import React, { useContext } from 'react'

import Multistep from 'components/multistep/Multistep'
import { LifeCycleStatus } from 'constants/enums/lifeCycleStatus'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { IApplicationContext } from 'interfaces/i-application-context-props'
import { IMultistepComponent } from 'interfaces/i-multistep'
import { CheckIsLifeCycleStatus } from 'utils/loanHelpers'
import ThankYou from 'components/application-blocks/common-blocks/ThankYou/ThankYou'
import { UploadDocumentsSe } from 'components/application-blocks/application-offer-blocks/UploadDocuments/UploadDocumentsSe'
import { SignPnSe } from 'components/application-blocks/application-offer-blocks/SignPn/SignPnSe'
import MissingKyc from 'components/application-blocks/application-offer-blocks/MissingKyc/MissingKyc'
import { PpiSe } from 'components/application-blocks/application-offer-blocks/Ppi/PpiSe'

export const LoanApplicationStepsSe = () => {
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const loanApplicationSteps: IMultistepComponent[] = [
		{
			id: 1,
			header: 'SignPromissoryNote',
			body: <PpiSe />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 2,
			header: 'BeforeLoanDisbursement',
			body: <SignPnSe />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 3,
			header: 'forms:ApplicationMissingKyc',
			body: <MissingKyc />,
			show:
				CheckIsLifeCycleStatus(
					[
						LifeCycleStatus.DocumentsUpload,
						LifeCycleStatus.ReceiveSigning,
					],
					loanApplication.applicationData?.lifeCycleStatus!
				) && !loanApplication.applicationData?.kycUpdated,
			stepCompleted: false,
		},
		{
			id: 4,
			header: 'UploadDocument',
			body: <UploadDocumentsSe />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.ReceiveSigning,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
		{
			id: 5,
			header: 'ThankYou',
			body: <ThankYou />,
			show: CheckIsLifeCycleStatus(
				[
					LifeCycleStatus.ReceiveSigning,
					LifeCycleStatus.DocumentsUpload,
					LifeCycleStatus.InternalCheck,
					LifeCycleStatus.SendToBo,
				],
				loanApplication.applicationData?.lifeCycleStatus!
			),
			stepCompleted: false,
		},
	]

	return (
		<Multistep
			multisteps={loanApplicationSteps}
			resetInitialState={false}
		/>
	)
}
