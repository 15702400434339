import React, { useContext, useEffect, useState } from 'react'
import { FormBuilder, formKeySelector } from '@tf/formbuilder'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SmoothCollapse from 'react-smooth-collapse'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import {
	ApplicationDispatchContext,
	LOAN_APPLICATION_ACTIONS,
} from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import { FormFactory } from 'formBuilder/formFactory'
import { CustomerForm } from 'constants/enums/formTypes'
import { getLanguageLocale, getMarket } from 'utils/countryHelpers'
import { IApplicationInformation } from 'interfaces/i-application-information'
import updateBankInfoServices from 'services/updateBankInfoServices'
import updateApplicationServices from 'services/updateApplicationServices'
import pnServices from 'services/pnServices'
import getApplicationServices from 'services/getApplicationServices'
import { SigningUrlType } from 'constants/enums/signingUrlTypes'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { MarketingConsentCheckbox } from '../MarketingConsent/MarketingConsent'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './UpdateBankInfo.scss'

export const UpdateBankInfoDk = () => {
	const { t } = useTranslation()
	const { loanApplication, dispatchLoanApplication }: IApplicationContext =
		useContext(ApplicationDispatchContext)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>('')
	const [addPbs, setAddPbs] = useState<boolean | undefined>(undefined)

	let formSchema = FormFactory(
		CustomerForm.ApplicationBankInfoForm,
		{
			formName: 'bankInfoForm',
			blockName: 'bankInfoForm',
			market: getMarket(),
			locale: getLanguageLocale(),
			getDataUrl: '',
			validationErrorList: false,
			requiredMessage: t('ValidateRequired'),
			submitButtonText: t('Next'),
		},
		loanApplication.applicationData as IApplicationInformation
	)

	const formStateValues = useSelector(
		formKeySelector(formSchema.formMeta.formName, 'controlsState')
	)

	const updateBankInfo = (formValues: any) => {
		dispatchLoanApplication({
			type: LOAN_APPLICATION_ACTIONS.SET_UPDATE_APPLICATIONDATA,
			payload: {
				...loanApplication,
				updateApplicationData: {
					...loanApplication.updateApplicationData,
					bankInfo: {
						...loanApplication.updateApplicationData?.bankInfo,
						directDebitClearingNumber:
							formValues?.directDebitClearingNumber?.value,
						directDebitAccount:
							formValues?.directDebitAccount?.value,
					},
				},
			},
		})
	}
	useEffect(
		() => {
			updateBankInfo(formStateValues)
		},
		[formStateValues] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const updateDirectDebitAccountNumber = async () => {
		setLoading(true)
		setError(false)
		if (addPbs === false) {
			try {
				await updateBankInfoServices
					.removeDirectDebit(
						loanApplication.applicationData?.loanNumber!
					)
					.then(async (response) => {
						if (response === 200) {
							await printPn()
							await allowMarketing()
						}
					})
					.catch((error) => {
						console.warn(error.message)
						setErrorMsg(t('ErrorMessageAccountNr'))
						setError(true)
						setLoading(false)
					})
			} catch (error) {
				console.warn(error)
				setErrorMsg(t('ErrorMsg'))
				setError(true)
				setLoading(false)
			}
		} else {
			try {
				await updateBankInfoServices
					.updateBankInfo({
						applicationId:
							loanApplication.applicationData?.loanNumber!,
						bankInfo:
							loanApplication.updateApplicationData?.bankInfo!,
					})
					.then((response) => {
						if (response === 200) {
							printPn()
							if (
								loanApplication.customerConsents?.allowMarketing
							) {
								allowMarketing()
							}
						}
					})
					.catch((error) => {
						console.warn(error.message)
						setErrorMsg(t('ErrorMessageAccountNr'))
						setError(true)
						setLoading(false)
					})
			} catch (error) {
				console.warn(error)
				setErrorMsg(t('ErrorMsg'))
				setError(true)
				setLoading(false)
			}
		}
	}

	const allowMarketing = async () => {
		await updateApplicationServices
			.updateCustomerConsent(loanApplication.customerConsents!, t)
			.then((response) => {
				if (response === 200) {
					console.log('response ', response)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	const printPn = async () => {
		setLoading(true)
		try {
			await pnServices
				.printPn(loanApplication.applicationData?.loanNumber!, t)
				.then(async (response) => {
					if (response === 200) {
						loanApplication.setSigningUrls(
							await getApplicationServices
								.GetSigningUrls(
									loanApplication.applicationData
										?.loanNumber!,
									SigningUrlType.ApplicantSigning,
									t
								)
								.then((response) => {
									if (response) {
										dispatchMsp({
											type: MSP_ACTIONS.SET_INDEX,
											payload: {
												index: msp.index + 1,
											},
										})
									}
								})
						)
					}
				})
				.catch((err) => {
					console.warn('Caught error on printPN: ', err)
					setErrorMsg(t('ErrorMsg'))
					setError(true)
				})
		} catch (err) {
			console.warn(err)
			setErrorMsg(t('ErrorMsg'))
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const checkIsValidAccountNr = () => {
		const clearingNr =
			loanApplication.updateApplicationData?.bankInfo
				?.directDebitClearingNumber
		const accountNr =
			loanApplication.updateApplicationData?.bankInfo?.directDebitAccount
		return (
			clearingNr?.length === 4 &&
			accountNr?.length! >= 7 &&
			accountNr?.length! <= 10
		)
	}

	return (
		<div className="update-bank-info denmark">
			{loading ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<>
					<div className="update-bank-info-section">
						<div className="update-bank-info-description">
							<Trans t={t} i18nKey="UpdateAccountNrDescription">
								<h6>Add Betalingsservice (OPTIONAL)</h6>
								<p>
									With Betalingsservice you will get automatic
									payment of your loan at TF Bank. It is
									supersmart, cause it means you'll never need
									to manually enter a payment.
								</p>
								<p>
									If you decline to add Betalingsservice, then
									your invoice will arrive to your e-Boks.
								</p>
							</Trans>
						</div>
						<div className="update-bank-info-select-container">
							<div>
								<label className="radio-button-type">
									<input
										value="Yes"
										type="radio"
										name="add-account-nr"
										checked={addPbs === true}
										onChange={() => setAddPbs(true)}
									/>
									<span>
										<Trans
											t={t}
											i18nKey="UpdateAccountNrAddAccountNr">
											<b>Yes please,</b> I would like to
											subscribe to betalingsservice. Make
											sure that the account number is
											filled out and that it is correct.
											If not, then please add a correct
											account number.
										</Trans>
									</span>
								</label>
								<SmoothCollapse
									expanded={addPbs === true}
									children={
										<div className="update-bank-info-add-accountnr-container">
											{formSchema ? (
												<>
													<FormBuilder
														formSchema={formSchema}
														apiError={undefined}
														showErrorsInForm={true}
														showSubmitButton={false}
													/>
													<div className="update-bank-info-add-accountnr-description">
														{t(
															'UpdateAccountNrAddAccountNrDescription'
														)}
													</div>
												</>
											) : (
												<CircleSpinner />
											)}
										</div>
									}
								/>
								<label className="radio-button-type">
									<input
										value="No"
										type="radio"
										name="remove-account-nr"
										checked={addPbs === false}
										onChange={() => setAddPbs(false)}
									/>
									<span>
										<Trans
											t={t}
											i18nKey="UpdateAccountNrRemoveAccountNr">
											<b>No thank you,</b> I would not
											like to subscribe to
											Betalingsservice.
										</Trans>
									</span>
								</label>
							</div>
						</div>
					</div>
					<MarketingConsentCheckbox
						allowMarketing={
							loanApplication.applicationData?.customerConsents
								.allowMarketing
						}
					/>
					{error && (
						<div className="error-box-container">
							<ErrorBox message={errorMsg} />
						</div>
					)}
					<div className="update-bank-info-next-button">
						<button
							disabled={
								addPbs === undefined ||
								(addPbs === true && !checkIsValidAccountNr())
							}
							type="button"
							className="btn btn-primary"
							onClick={() => updateDirectDebitAccountNumber()}>
							{t('Next')}
						</button>
					</div>
				</>
			)}
		</div>
	)
}
