import appConfig from 'app.config'

declare global {
    interface Window {
        CookieConsent: any;
    }
}

window.addEventListener('CookiebotOnConsentReady', function () {
    if (appConfig.country === 'es')
        ExternalServices.initializeSymplifyServices()
})

const ExternalServices = {
    initializeSymplifyServices() {
        if (window.CookieConsent?.consent?.marketing) {
            const symplifyScript = document.createElement('script')
            symplifyScript.setAttribute('async', 'true')
            symplifyScript.setAttribute('type', 'text/javascript')
            symplifyScript.setAttribute('src', appConfig.symplifyScriptUrl)
            document.head.insertBefore(symplifyScript, document.head.firstChild)
        }
    },
    initializeCookiebot(cookiebotId: string) {
        ExternalServices.initializeGoogleConsent()
        const script = document.createElement('script')
        script.setAttribute('id', 'CookieBot')
        script.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
        script.setAttribute('data-cbid', cookiebotId)
        script.setAttribute('data-blockingmode', 'auto')
        script.setAttribute('data-consentmode-defaults', 'disabled')
        script.setAttribute('type', 'text/javascript')

        document.head.appendChild(script)
    },
    initializeGoogleConsent() {
        const script = document.createElement('script')
        script.setAttribute('data-cookieconsent', 'ignore')
        script.innerHTML =
            `window.dataLayer = window.dataLayer || [];
			function gtag() { dataLayer.push(arguments); }
			gtag("consent", "default", {
				ad_personalization: "denied",
				ad_storage: "denied",
				ad_user_data: "denied",
				analytics_storage: "denied",
				functionality_storage: "denied",
				personalization_storage: "denied",
				security_storage: "granted",
				wait_for_update: 500,
			});
			gtag("set", "ads_data_redaction", true);
			gtag("set", "url_passthrough", true);`
        document.head.appendChild(script)
    },
    initializeGoogleTagManger(gtmId: string) {
        const script = document.createElement('script')
        script.setAttribute('data-cookieconsent', 'ignore')
        script.innerHTML =
            `(function(w, d, s, l, i) {
				w[l] = w[l] || [];
				w[l].push({
					'gtm.start':
						new Date().getTime(),
					event: 'gtm.js'
				});
				var f = d.getElementsByTagName(s)[0],
					j = d.createElement(s),
					dl = l != 'dataLayer' ? '&l=' + l : '';
				j.async = true;
				j.src =
					'//www.googletagmanager.com/gtm.js?id=' + i + dl;
				f.parentNode.insertBefore(j, f);
			})(window, document, 'script', 'dataLayer', '${gtmId}');`
        const noScript = document.createElement('noscript')
        noScript.innerHTML = `<iframe src="//www.googletagmanager.com/ns.html?id=${gtmId}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        document.head.appendChild(script)
        document.head.appendChild(noScript)
    },
}

export default ExternalServices;