import appConfig from 'app.config'
import { TranslationFile } from 'i18n'
import { IApplicationInformation } from 'interfaces/i-application-information'
import { useTranslation } from 'react-i18next'

export const ApplicationMissingKycFormSe = (
	_applicationData: IApplicationInformation
) => {
	const { t } = useTranslation([
		TranslationFile.Translation,
		TranslationFile.Forms,
		TranslationFile.Dropdown,
	])

	return {
		fieldSetName: '',
		formName: 'MissingKycForm',
		description: '',
		collapsable: false,
		visible: true,
		market: appConfig.country,
		fullSize: true,
		col: 12,
		controls: [
			{
				options: [
					{
						value: false,
						label: t('No'),
					},
					{
						value: true,
						label: t('Yes'),
					},
				],
				required: true,
				controlName: 'pep',
				type: 'dropdown',
				label: t('forms:PoliticallyExposedPerson'),
				value: false,
				visible: true,
				tooltip: t('forms:PEPInfoDescription'),
				col: 12,
			},
			{
				options: [
					{
						value: false,
						label: t('No'),
					},
					{
						value: true,
						label: t('Yes'),
					},
				],
				required: true,
				controlName: 'connectedToPep',
				type: 'dropdown',
				label: t('forms:ConnectedToPep'),
				value: false,
				visible: true,
				col: 12,
			},
			{
				options: [
					{
						value: false,
						label: t('No'),
					},
					{
						value: true,
						label: t('Yes'),
					},
				],
				required: true,
				controlName: 'beneficialOwner',
				type: 'dropdown',
				label: t('forms:BeneficialOwner'),
				value: true,
				visible: true,
				col: 12,
			},
		],
	}
}
