import { logEvent } from 'appinsights/appinsights'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import { appRoutes } from 'constants/routes/appRoutes'
import React, { useState, useEffect, useCallback } from 'react'
import { Container, Alert } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import authenticationService from 'services/authServices'
import { getErrorWithFullMessage, getReturnUrl, maskSsn } from 'utils/authHelpers'

function BankIdCallbackPage() {
	const [error, setError] = useState<Error | null>(null)
	const [isWaiting, setIsWaiting] = useState(true)
	const { t } = useTranslation()

	useEffect(() => {
		signin()
		// eslint-disable-next-line
	}, [])

	const cancelBankIdAuth = useCallback(async () => {
		const orderRef = localStorage.getItem('bankid_orderRef')
		if (!orderRef) return
		try {
			await authenticationService.bankIdv2AuthCancel(orderRef, t)
		} catch (err) {
			setError(new Error(t('BankIdCancelFailure')))
		}
	}, [t])

	async function signin() {
		setIsWaiting(true)

		try {
			const orderRef = localStorage.getItem('bankid_orderRef')
			if (orderRef) {
				const completeData =
					await authenticationService.bankIdv2AuthCollect(
						orderRef,
						true,
						t,
					)
				logEvent(
					`User (${maskSsn(
						completeData.ssn,
					)}) completed BankId signin with status:${completeData?.isCompleted}`,
					{
						ssn: maskSsn(completeData.ssn),
						status: completeData?.isCompleted
							? 'success'
							: 'failed',
					},
				)

				if (completeData?.isCompleted) {
					authenticationService.redirectTo(
						getReturnUrl(),
						completeData.secret,
					)
				} else {
					throw new Error(t('BankIDCollectFailure'))
				}
			} else {
				throw new Error(t('BankIDCollectFailure'))
			}
		} catch (error: any) {
			setError(getErrorWithFullMessage(error))
			await cancelBankIdAuth() // Call cancel method if an error occurs
		} finally {
			setIsWaiting(false)
		}
	}

	return (
		<Container className="spinner-container d-flex justify-content-center align-items-center">
			{error ? (
				<Alert
					variant="danger"
					dismissible
					onClose={() => (window.location.href = appRoutes.login)}>
					{error.message}
				</Alert>
			) : isWaiting ? (
				<div className="col-spinner">
					<CircleSpinner />
				</div>
			) : (
				<></>
			)}
		</Container>
	)
}

export default BankIdCallbackPage
