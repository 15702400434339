import appConfig from 'app.config'
import { CustomerForm } from 'constants/enums/formTypes'
import { ApplicationMissingKycFormFi } from './forms/applicationMissingKycFormFi'
import { FormWrapper } from './formWrapper'
import { ApplicationMissingKycFormDk } from './forms/applicationMissingKycFormDk'
import { ApplicationMissingKycFormNo } from './forms/ApplicationMissingKycFormNo'
import { ApplicationPersonalInformationForm } from './forms/applicationPersonalInformationForm'
import { ApplicationAddressForm } from './forms/applicationAddressForm'
import { ApplicationEmploymentForm } from './forms/applicationEmploymentForm'
import { ApplicationExpensesForm } from './forms/applicationExpensesForm'
import { ApplicationMortgageForm } from './forms/applicationMortgageForm'
import { ApplicationContactInformationForm } from './forms/applicationContactInformationForm'
import {
	ApplicationBankInfoForm,
	ApplicationBankInfoFormFi,
	ApplicationBankInfoFormDk,
} from './forms/applicationBankInfoForm'
import { ApplicationLoanOfferForm } from './forms/applicationLoanOfferForm'
import { OtpStartFormEs } from './forms/otpStartFormEs'
import { OtpCompleteForm } from './forms/otpCompleteForm'
import { ApplicationMissingKycFormSe } from './forms/ApplicationMissingKycFormSe'

export function FormFactory(formType: CustomerForm, formMeta: any, data?: any) {
	const country = appConfig.country
	switch (formType) {
		case CustomerForm.ApplicationMissingKyc:
			if (country === 'fi')
				return FormWrapper(
					[ApplicationMissingKycFormFi(data)],
					formMeta
				)
			if (country === 'dk')
				return FormWrapper(
					[ApplicationMissingKycFormDk(data)],
					formMeta
				)
			if (country === 'no')
				return FormWrapper(
					[ApplicationMissingKycFormNo(data)],
					formMeta
				)
			if (country === 'se')
				return FormWrapper(
					[ApplicationMissingKycFormSe(data)],
					formMeta
				)
			break
		case CustomerForm.ApplicationPersonalInformationForm:
			return FormWrapper(
				[ApplicationPersonalInformationForm(data)],
				formMeta
			)
		case CustomerForm.ApplicationAddressForm:
			return FormWrapper([ApplicationAddressForm(data)], formMeta)
		case CustomerForm.ApplicationEmploymentForm:
			return FormWrapper([ApplicationEmploymentForm(data)], formMeta)
		case CustomerForm.ApplicationExpensesForm:
			return composeForm(
				[
					ApplicationExpensesForm(data),
					ApplicationMortgageForm(data),
					ApplicationContactInformationForm(data),
				],
				formMeta
			)
		case CustomerForm.ApplicationContactInformationForm:
			return FormWrapper(
				[ApplicationContactInformationForm(data)],
				formMeta
			)
		case CustomerForm.OtpStartFormEs:
			return FormWrapper([OtpStartFormEs(data)], formMeta)
		case CustomerForm.OtpCompleteForm:
			return FormWrapper([OtpCompleteForm(data)], formMeta)
		case CustomerForm.ApplicationLoanOfferForm:
			return FormWrapper([ApplicationLoanOfferForm(data)], formMeta)
		case CustomerForm.ApplicationBankInfoForm:
			if (country === 'es')
				return FormWrapper([ApplicationBankInfoForm(data)], formMeta)
			if (country === 'fi')
				return FormWrapper([ApplicationBankInfoFormFi(data)], formMeta)
			if (country === 'dk')
				return FormWrapper([ApplicationBankInfoFormDk(data)], formMeta)
			if (country === 'se')
				return FormWrapper([ApplicationBankInfoForm(data)], formMeta)
			break
		default:
			return Object.assign({})
	}
}

const composeForm = (formPartials: any, formMeta: any) => {
	return FormWrapper(formPartials, formMeta)
}
