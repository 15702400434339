import React, { useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IApplicationContext } from 'interfaces/i-application-context-props'
import { ApplicationDispatchContext } from 'contexts/ApplicationContext'
import { MSP_ACTIONS, MspDispatchContext } from 'components/multistep/Multistep'
import updateApplicationServices from 'services/updateApplicationServices'
import CircleSpinner from 'components/circle-spinner/CircleSpinner'
import ErrorBox from 'components/message-boxes/error-box/ErrorBox'

import './Ppi.scss'
import { MarketingConsentCheckbox } from '../MarketingConsent/MarketingConsent'
import { ReadMoreExpander } from 'components/read-more-expander/ReadMoreExpander'

export const PpiSe = () => {
	const { t } = useTranslation()
	const { loanApplication }: IApplicationContext = useContext(
		ApplicationDispatchContext
	)
	const { msp, dispatchMsp } = useContext(MspDispatchContext)
	const [ppiStatus, setPpiStatus] = useState<boolean>(false)
	const [gdprConsent, setGdprConsent] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const [errorMsg, setErrorMsg] = useState<string>('')

	const updateDataAndContinue = async () => {
		setLoading(true)
		setError(false)
		try {
			await allowMarketing()
						
			if (loanApplication.applicationData?.ppi) {
				await updateApplicationServices
					.updatePpi(
						{
							ppi: ppiStatus,
							applicationId:
								loanApplication.applicationData
									?.loanNumber!,
						},
						t
					)
					.then((response) => {
						if (response === 200) {
							dispatchMsp({
								type: MSP_ACTIONS.SET_INDEX,
								payload: {
									index: msp.index + 1,
								},
							})
						}
					})
			} else {
				dispatchMsp({
					type: MSP_ACTIONS.SET_INDEX,
					payload: {
						index: msp.index + 1,
					},
				})
			}
		} catch (err) {
			console.log(err)
			setErrorMsg(t('ErrorMsg'))
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const allowMarketing = async () => {
		await updateApplicationServices
			.updateCustomerConsent(loanApplication.customerConsents!, t)
			.then((response) => {
				if (response === 200) {
					console.log('response ', response)
				}
			})
			.catch((error) => {
				console.error(error)
			})
	}

	return (
		<div className="payment-protection-insurance">
			{loading ? (
				<CircleSpinner />
			) : (
				<div className="ppi-section">
					<div>
						<p className="ppi-description">{t('PpiDesc')}</p>
						<MarketingConsentCheckbox
							allowMarketing={
								loanApplication.applicationData
									?.customerConsents.allowMarketing
							}
						/>
						<label className="checkbox-container mt-2">
							<input
								type="checkbox"
								name="gdprConsent"
								onChange={() => setGdprConsent(!gdprConsent)}
								checked={gdprConsent}
							/>
							<span className="checkmark"></span>
							<p>
								<Trans t={t} i18nKey="GdprLabel">
									I confirm that I have read and understood
									the information about how TF Bank processes
									my personal data
									<a
										rel="noopener noreferrer"
										target="_blank"
										className="text-link link-darkgray"
										href="https://credento.se/integritetspolicy">
										LINK
									</a>
								</Trans>
							</p>
						</label>
						{loanApplication.applicationData?.ppi ? (
							<label className="checkbox-container mt-2">
								<input
									type="checkbox"
									name="ppi"
									onChange={() => setPpiStatus(!ppiStatus)}
									checked={ppiStatus}
								/>
								<span className="checkmark"></span>
								<p>{t('PpiLabel')}</p>
							</label>
						) : null}
						<div className="ppi-terms se">
							<div>{t('PpiLine1')}</div>
							<div>
								<Trans t={t} i18nKey="PpiLine2">
									Based on the information you provided in the
									application, TF Bank has carried out a
									requirements and needs assessment that shows
									whether you benefit from this insurance.
									Read more about requirements and needs
									assessment
									<a
										rel="noopener noreferrer"
										target="_blank"
										className="text-link link-darkgray"
										href="https://credento.se/media/odknehfm/sverige_krav-och-behovsbedomning-1_0-11102024.pdf">
										here
									</a>
								</Trans>
							</div>

							<ReadMoreExpander
								expanded={false}
								showExpander={true}
								contentTop={true}
								collapsedHeight={'2rem'}
								children={
									<>
										<div>{t('PpiTermsReadMore')}</div>
										<Trans
											t={t}
											i18nKey="PpiMoreInfo">
												<div>
													En betalningsförsäkring ger dig ekonomisk trygghet om en oförutsedd händelse skulle drabba dig.
												</div>
												<div>
													<b>Försäkringen omfattar:</b>
													<ul>
														<li>
															Ofrivillig förlust av arbete.
														</li>
														<li>
															Tillfällig och fullständig arbetsoförmåga på grund av plötslig och oväntad olycka eller sjukdom.
														</li>
														<li>
															Sjukhusvistelse.
														</li>
														<li>
															Dödsfall.
														</li>
													</ul>
												</div>
												<div>
													<b>Vem kan få försäkring:</b>
													<ul>
														<li>
															Privatperson eller egenföretagare mellan 18 och 64 år eller 65 – 75 år.
														</li>
														<li>
															Fast anställd hos en och samma arbetsgivare under en period på minst sex sammanhängande månader direkt före försäkringen tecknas.
														</li>
														<li>
															Har en anställning med minst 22 timmar per vecka.
														</li>
														<li>
															Är fullt arbetsför och mottar inte sjukpenning, arbetslöshetsersättning eller liknande.
														</li>
														<li>
															Du får inte vara medveten om en förestående sjukhusinläggning, arbetslöshet eller permittering.
														</li>
														<li>
															Medlem i den svenska socialförsäkringen vid tidpunkten för att försäkringen tecknas (gäller ej egenföretagare).
														</li>
													</ul>
												</div>
												<div>
													Försäkringsbevis och fullständiga villkor hittar du via länken nedan:
												</div>
												<div className="ppi-link-section">
													<b>Läs mer</b>
													<a
														href={
															'https://credento.se/media/12gpwgxf/36351_fki-18-64_lpi-tf-bank_sv_2_0.pdf'
														}
														className="link-darkgray"
														target="_blank"
														rel="noopener noreferrer">
														Se Produktinformation Låneskydd för dig mellan 18-64 år
													</a>
													<a
														href={
															'https://credento.se/media/n0kmqmcu/36351_fki-65-75_lpi-tf-bank_sv_2_0.pdf'
														}
														className="link-darkgray"
														target="_blank"
														rel="noopener noreferrer">
														Se Produktinformation Låneskydd för dig mellan 65 - 75 år
													</a>
													<a
														href={
															'https://credento.se/media/amvfwvka/tf-se_villkor_l%C3%A5neskydd_7_0.pdf'
														}
														className="link-darkgray"
														target="_blank"
														rel="noopener noreferrer">
														Se standardiserat informationsdokument för låneförsäkring
													</a>
												</div>
											</Trans>
									</>
								}
							/>
						</div>
					</div>
					{error ? (
						<div className="error-box-container">
							<ErrorBox message={t('PpiError')} />
						</div>
					) : null}
				</div>
			)}
			{error && (
				<div className="error-box-container">
					<ErrorBox message={errorMsg} />
				</div>
			)}

			<div className="ppi-next-button">
				<button
					className="btn btn-primary"
					onClick={() => updateDataAndContinue()}
					disabled={!gdprConsent || loading}>
					{t('PpiNext')}
				</button>
			</div>
		</div>
	)
}
